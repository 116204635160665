/* Tabs */
.nav-link {
	cursor: pointer;
	&:focus {
		outline: none !important;
	}
}

.nav-item, .nav-item:focus, .nav-link{
	outline: none;
}

.nav.nav-tabs {
	border-bottom-right-radius: .3rem;
	border: .1rem solid #d7d7d7;
	border-top-width: 0;
	border-right-width: 0;
	border-left-width: 0;
	
	.nav-link {
		color: #777;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 1.5;
		letter-spacing: -.025em;
		padding: .85rem 2.2rem;
		@include direction {
            #{$border-start-start-radius}: .3rem;
            #{$border-start-end-radius}: .3rem;
        }
		text-align: center;
		transition: all .35s ease;

		&:hover,
		&:focus {
			color: $primary-color;
			border-color: transparent;
		}
	}

	.nav-item {
		& + .nav-item {
			margin-left: .1rem;
		}

		&.show .nav-link,
		.nav-link.active {
			color: $primary-color;
			border-color: #d7d7d7;
			border-bottom-color: transparent;
		}
	}

	.nav-tabs-bg & {
		.nav-link:not(.active) {
			border-color: #ebebeb  #ebebeb #d7d7d7 #ebebeb;
			background-color: #fafafa;
		}
	}

	// &.nav-tabs-bg {
	// 	.nav-link {
	// 		border-color: #ebebeb  #ebebeb #d7d7d7 #ebebeb;
	// 		background-color: #fafafa;
	// 	}
	// 	.nav-item.show .nav-link {
	// 		color: $primary-color;
	// 		border-color: #d7d7d7;
	// 		border-bottom-color: transparent;
	// 		background-color: #fff;
	// 	}
	// }

	&.justify-content-center {
		border-bottom-left-radius: .3rem;
		border-bottom-right-radius: .3rem;
	}
}

.tab-content-border {
	.justify-content-center + & {
		@include direction {
            #{$border-start-start-radius}: .3rem;
        }
	}

	.tab-content {
		border: .1rem solid #d7d7d7;
		border-radius: .3rem;
		@include direction {
            #{$border-start-start-radius}: 0;
        }
		border-top-width: 0;
	}
}

.tab-pane {
	visibility: hidden;
	opacity: 0;
	height: 0;
	animation: fadeAnim;
	animation-duration: .8s;
	animation-fill-mode: forwards;

	p:last-child {
		margin-bottom: 0;
	}

	&.active {
		height: auto;
		visibility: visible;
		opacity: 1;
	}

	&:not(.active) {
		.owl-stage-outer {
			margin-top: 0;
			padding-top: 0;
		}
	}
}

/* Nav Pills - Line Tabs */
.nav.nav-pills {
	.nav-link {
		color: #777;
		font-weight: 400;
		font-size: 1.4rem;
		line-height: 1.5;
		letter-spacing: -.01em;
		padding: .45rem 1rem;
		border-radius: 0;
		text-align: center;
		text-transform: uppercase;
		border: none;
		border-bottom: .1rem solid transparent;
		background-color: transparent;
		transition: all .35s ease;

		&:hover,
		&:focus {
			color: $primary-color;
		}

		&:focus {
			outline: none !important;
		}
	}

	.nav-item {
		& + .nav-item {
			margin-left: 1rem;
		}

		&.show .nav-link,
		.nav-link.active {
			color: $primary-color;
			border-color: #d7d7d7;
			border-bottom-color: $primary-color;
		}
	}
}

.nav-pills {
	& + .tab-content {
		.tab-pane {
			padding: 1.4rem 0;

			p:last-child {
				margin-bottom: 0;
			}
		}
	}
}

/* Vertical Tabs */
.tabs-vertical {
	display: flex;
	align-items: flex-start;
	align-items: stretch;

	.tab-content {
		padding-left: 1rem;;
	}

	.nav {
		flex-shrink: 0;
		.nav-link {
			min-width: 100px;
		}
	}
	.nav.nav-tabs {
		border-bottom-width: 0;
		border-right-width: .1rem;

		.nav-link {
			@include direction {
				#{$border-start-start-radius}: .3rem;
				#{$border-start-end-radius}: 0;
				#{$border-end-start-radius}: .3rem;
			}
		}

		.nav-item {
			margin-bottom: 0;
			margin-right: -1px;
			& + .nav-item {
				margin-left: 0;
				margin-top: 1px;
			}

			&.show .nav-link,
			.nav-link.active {
				border-color: #d7d7d7;
				border-right-color: transparent;
			}
		}

		.nav-tabs-bg {
			.nav-link:not(.active) {
				border-color: #ebebeb  #d7d7d7 #ebebeb  #ebebeb;
				background-color: #fafafa;
			}
		}
	}
}


.nav.nav-border-anim {
	.nav-link {
		position: relative;
		border-bottom: none;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left:0;
			bottom: 0;
			width: 100%;
			height: .1rem;
			background-color: $primary-color;
			transform-origin: right center;
			transform: scale(0, 1);
			transition: transform .3s ease;

			.form-tab & {
				height: .2rem;
			}
		}

		&.active {
			&:before {
				transform-origin: left center;
				transform: scale(1, 1);
			}
		}
	}
	.nav-item {
		&.show .nav-link {
			&:before {
				transform-origin: left center;
				transform: scale(1, 1);
			}
		}
	}
	&.nav-pills-mobile {
		.nav-link:before {
			height: .2rem;
		}
	}
}

.tab-content-carousel {
	.tab-pane {
		transition: .5s;
		animation: none;
	
		&:not(.active) {
			display: block;
			transition: none;

			owl-carousel-child {
				display: flex;
			}
		}
	}

	.tab-content > .tab-pane {
		padding: 0;
	}
}