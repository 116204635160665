@import 'mixins/preloader';
$primary-color: #02066F;
$secondary-color: #2000B1;
$light-color: #c5e7ff;
$dark-gray: #262731;
$light-gray: #EBEAED;
$text-color: #ED4B00;

.text-third {
	color: $text-color;
}

.bg-light {
	background-color: #f4f7ff !important;
}

.title-desc {
	font-size: 1.6rem;
	line-height: 1;
	margin-top: .3rem;
}

.cart-dropdown {
	.btn-primary {
		color: white;
		background-color: $primary-color;
		border-color: $primary-color;

		&:hover {
			color: $primary-color;
			border-color: $primary-color;
			background-color: white;
		}
	}

	.btn-outline-primary-2 {
		color: $primary-color;
		background-color: white;
		border-color: $primary-color;

		&:hover {
			color: white;
			background-color: $primary-color;
		}
	}
}

.intro-slider-container {
	// height: 400px;
	background-color: #f5f6f9;
	@include mq('md', 'max') {
		.owl-carousel.owl-drag .owl-item {
			height: 50vw;
		}
	}
	
	@include mq('md') {
		.owl-carousel.owl-drag .owl-item {
			height: 25vw;
		}
	}
}


.intro-slide {

	align-items: center;
	background-size: cover;
	background-position: 50%;
	height: 100%;

	.intro-content {
		position: static;
		left: auto;
		top: auto;
		transform: translateY(0);
		-ms-transform: translateY(0);
	}
}

@include mq('md', 'max') {

	// mobile
	.intro-slide--desktop {
		display: none;
	}

	.intro-slide--mobile {
		display: flex;
	}
}

@include mq('md') {
	.intro-slide--desktop {
		// desktop
		display: flex;
	}

	.intro-slide--mobile {
		display: none;
	}
}

.intro-subtitle {
	color: #ef837b;
	font-weight: 300;
	font-size: 1.4rem;
	letter-spacing: -.01em;
	margin-bottom: .5rem;
}

.intro-title {
	font-size: 3.4rem;
	font-weight: 700;
	line-height: 1.2;
	text-indent: -.2rem;
	color: #333;
	letter-spacing: -.03em;
	margin-bottom: 0;
}

.intro-price {
	color: #777;
	font-weight: 700;
	line-height: 1.2;
	font-size: 3.4rem;
	letter-spacing: -.025em;
	margin-top: 1rem;
	margin-bottom: 1.2rem;

	sup {
		top: -.8em;
		font-weight: 300;
		font-size: 1.4rem;
		letter-spacing: -.03em;
	}
}

.intro-old-price {
	position: relative;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		position: absolute;
		top: 50%;
		left: 0;
		background-color: #999;
	}
}

.home-page {
	.product-media {
		&:before {
			@include aspect-ratio(277, 277);
		}
	}

	.product-cat {
		color: #ccc;
	}

	.product-body {
		overflow: hidden;
	}

	.btn-product+.btn-product {
		border-left-style: solid;
	}

	.product-action:not(.product-action-dark) {
		background-color: #f6f7fa;

		.btn-product+.btn-product {
			border-left-color: #d1d1d3;
		}

		.btn-product:not(:hover):not(:focus) {
			color: #333;
		}
	}

	.product-action-dark .btn-product:not(:hover):not(:focus) {
		color: #fff;
	}

	.ratings-container {
		margin-bottom: 1.5rem;
	}

	// .btn-product-icon {
	// color: $primary-color;
	// background-color: white;
	// border: .1rem solid $primary-color;

	// span {
	// 	background-color: $primary-color;
	// 	top: -1px;
	// 	bottom: -1px;
	// }

	// &:hover,
	// &:focus {
	// 	color: $primary-color;
	// 	border-color: $primary-color;
	// 	background-color: transparent;
	// }
	// }

	.btn-wishlist {

		&:hover,
		&:focus {
			&:before {
				content: '\f233';
			}
		}
	}

	.btn-more {
		text-transform: capitalize;
	}

	.new-price {
		font-weight: 400;
		letter-spacing: -.01em;
		width: 100%;
	}

	.btn-link {
		letter-spacing: -.01em;

		i:last-child {
			@include direction {
				#{$margin-inline-start}: .5rem;
			}
		}
	}

	// .banner-content {
	// 	left: 30px;
	// 	padding-top: 0;
	// }
	.banner-content {
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: 90%;
	}

	.banner-subtitle {
		font-size: 1.4rem;
		letter-spacing: -.01em;
		margin-bottom: 1.3rem;
	}

	// .banner-title {
	// 	color: $light-text;
	// 	font-weight: 400;
	// 	font-size: 1.6rem;
	// 	line-height: 1.25;
	// 	letter-spacing: -.01em;
	// 	margin-bottom: 1.2rem;
	// }

	.banner-title {
		font-weight: 700;
		font-size: 3rem;
		line-height: 3.5rem;
		color: #fff;
	}

	.banner-link {
		display: inline-block;
		font-weight: 400;
		line-height: 1.3;
		padding: .3rem 1rem;
		border-radius: 20px;
		letter-spacing: -.01em;
		margin-left: -1rem;
		text-align: center;
		min-width: 110px;
		transition: .3s;

		i {
			margin-left: .7rem;
		}

		&:hover,
		&:focus {
			color: #fff;
			background-color: $primary-color;
			text-decoration: none !important;
		}
	}

	.nav.nav-pills .nav-link {
		font-size: 1.4rem;
	}

	.nav.nav-pills .nav-item+.nav-item {
		margin-left: .5rem;
	}

	.heading .title {
		font-weight: 600;
		letter-spacing: -.025em;
	}

	.new-collection .tab-content {
		padding-bottom: 6rem;
	}

	.cta-border {
		position: relative;
		border: none;
		background-color: #666666;
		background-size: cover;
		font-size: 2rem;
		margin-left: 70px;
		padding-top: 4.9rem;
		padding-bottom: 4.9rem;

		.cta-img {
			position: absolute;
			left: -70px;
			top: -5px;
		}

		.cta-text {
			p {
				font-size: 2rem;
				line-height: 1.3;
				letter-spacing: -.01em;
				color: #fff;
			}

			margin-right: 0;

			&::before {
				display: none;
			}
		}

		p {
			max-width: none;

			strong {
				font-weight: 600;
			}
		}

		.btn:not(.btn-block) {
			min-width: 230px;
		}

		.btn {
			margin-right: 70px;
			margin-left: 70px;

			i:last-child {
				@include direction {
					#{$margin-inline-start}: 2.5rem;
				}
			}
		}
	}

	.cta-heading {
		margin-bottom: 2.2rem;

		.cta-title {
			margin-bottom: .2rem;
		}
	}

	.cta-title {
		font-weight: 600;
		font-size: 2.4rem;
		letter-spacing: -.025em;
	}

	.cta-desc {
		font-size: 1.6rem;
	}

	.cta .btn:not(.btn-block) {
		min-width: 178px;
	}
}

.product-action-dark .btn-product:not(:hover):not(:focus) {
	color: #fff;
}

.cat-blocks-container {
	padding-top: .5rem;

	[class*="col-"] {
		display: flex;
		align-items: stretch;
		justify-content: center;
	}
}

.cat-block {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	text-align: center;
	margin-bottom: 3rem;

	figure {
		display: inline-flex;
		align-items: center;
		position: relative;
		flex-grow: 1;
		margin: 0;

		span {
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -.2rem;
				left: 45%;
				width: 105%;
				margin-left: -45%;
				height: .3rem;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, .3);
				transition: all 0.35s ease;
				filter: blur(3px);
				opacity: .5;
			}
		}
	}

	img {
		margin-left: auto;
		margin-right: auto;
		padding: 6px;
		transition: transform 0.35s ease;
	}

	&:hover {
		img {
			transform: translateY(-14px);
		}

		figure {
			span:after {
				opacity: 1;
			}
		}
	}
}

.cat-block-title {
	color: $dark-text;
	font-weight: 400;
	font-size: 1.2rem;
	letter-spacing: -.01em;
	margin-top: 1rem;
	margin-bottom: 0;
}

.banners-group .lazy-media:before {
	padding-top: 16rem;
}

.main {
	.banner-title {
		strong {
			font-weight: 600;
			color: $dark-text;
		}
	}
}

.product.product-2 {
	background-color: #fff;
	border: .1rem solid #ebebeb;
	margin-bottom: 2rem;

	.product-nav-dots {
		margin-bottom: 1.5rem;
	}

	molla-image {
		background-color: #fff;
	}
}

// for border anfd backgrouns
.product.product-7 {
	background-color: #fff;
	border: .1rem solid #ebebeb;
	margin-bottom: 2rem;

	.product-nav-dots {
		margin-bottom: 1.5rem;
	}

	molla-image {
		background-color: #fff;
	}
}


.for-you {
	.product.product-2 {
		border: none;
	}
}

.trending-products {
	.product-body {
		padding: 1.6rem .4rem .4rem;
	}

	.banner {
		.lazy-media:before {
			padding-top: 35.8rem;
		}

		img {
			object-fit: initial;
		}
	}
}

@include only_for_edge () {
	.trending-products .banner img {
		height: auto;
	}
}

.deal-col {
	display: flex;
	align-items: stretch;
}

.deal {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	padding: 3.6rem 4rem 4.8rem;
	background-color: #f5f6f9;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	margin-bottom: 2rem;
	min-height: 420px;

	>div {
		width: 100%;
	}

	.btn {
		padding: 0 0 .2rem;
	}

	h4 {
		color: #777;
		font-weight: 300;
		font-size: 1.4rem;
		line-height: 1.25;
		letter-spacing: 0;
		margin-bottom: .5rem;
		max-width: 240px;
	}

	h2 {
		color: #ef837b;
		font-weight: 500;
		font-size: 2rem;
		line-height: 1.1;
		letter-spacing: -.01em;
		margin-bottom: .2rem;
		max-width: 240px;
	}

	.product-title {
		max-width: 240px;
		margin-bottom: .7rem;
	}

	.product-price {
		font-weight: 300;
		font-size: 2rem;
		margin-bottom: .4rem;
	}
}

.deal-content {
	align-self: center;
	margin-top: -2rem;
}

.deal-bottom {
	align-self: flex-end;
}

.btn.btn-outline-dark-2 {
	border-color: #d7d7d7;
}

.deal-countdown {
	margin-bottom: 0;
	margin-left: -.8rem;
	max-width: 180px;

	&.offer-countdown {
		max-width: 240px;
	}

	.countdown-show3 .countdown-section {
		width: calc(33.33% - 16px);
	}

	.countdown-show4 .countdown-section {
		width: calc(25% - 16px);
	}

	.countdown-section {
		position: relative;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1;
		padding: .9rem 0 .3rem;
		margin-left: .8rem;
		margin-right: .8rem;
		background-color: $primary-color;
		border-radius: .3rem;
		border: none;
		margin-bottom: 2rem;

		&:not(:last-child):after {
			color: #333333;
			content: ':';
			display: inline-block;
			font-weight: 400;
			font-size: 20px;
			line-height: 1;
			position: absolute;
			left: 100%;
			margin-left: 6px;
			margin-top: -1px;
			top: 50%;
			transform: translateY(-50%);
			-ms-transform: translateY(-50%);
		}
	}

	.countdown-period {
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		bottom: -2rem;
		display: block;
		font-weight: 400;
		color: #666666;
		text-transform: lowercase;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.countdown-amount {
		display: inline-block;
		color: #fff;
		font-weight: 500;
		font-size: 2.4rem;
		line-height: 1;
		letter-spacing: -.03em;
		margin-bottom: .4rem;
	}
}

@include mq('sm') {
	.intro-content {
		left: 50px;

		.btn {
			font-size: 1.2rem;
			min-width: 110px;
			padding-top: .75rem;
			padding-bottom: .75rem;
		}
	}

	.intro-subtitle {
		font-size: 1.6rem;
	}

	.intro-title {
		font-size: 3.6rem;
	}
}

@include mq('md') {
	.intro-content {
		left: 70px;

		.btn {
			font-size: 1.3rem;
			min-width: 130px;
			padding: .95rem 1.5rem;
		}
	}

	.intro-subtitle {
		font-size: 1.8rem;
	}

	.intro-title {
		font-size: 4.2rem;
	}

	.intro-price {
		font-size: 4rem;

		sup {
			font-size: 1.6rem;
		}
	}
}

@include mq('lg') {
	.intro-price {
		font-size: 4.4rem;

		sup {
			font-size: 1.8rem;
		}
	}

	.intro-title {
		font-size: 4.4rem;
	}
}

@include mq('xl') {
	.intro-content {
		left: 100px;

		.btn {
			font-size: 1.4rem;
			min-width: 170px;
			padding-top: 1.15rem;
			padding-bottom: 1.15rem;
		}
	}

	.intro-subtitle {
		font-size: 2rem;
	}

	.intro-title {
		font-size: 5rem;
	}

	.intro-price {
		font-size: 5rem;
		margin-bottom: 1.7rem;

		sup {
			top: -1.1em;
			font-size: 2rem;
		}
	}

	.btn-more {
		min-width: 270px;
	}
}

@include mq('xl', 'max') {
	.header-intro-clearance .header-bottom .container::after {
		display: none;
	}
}

@include mq('lg', 'max') {
	.heading {
		text-align: center;

		.title {
			margin-bottom: 1rem;
		}
	}

	.intro-slide {
		background-position-x: 59%;
	}
}
// mobile
@include mq('md', 'max') {
	.mobileSticky{
		position: fixed;
		z-index: 9;
		padding: 1rem 0;
		border-bottom: solid 0.5px rgba(0, 0, 0, 0.2);
	}
	.main{
		margin-top: 64px;
	}
	.mobile {
		display: block !important;
	}

	.desktop {
		display: none !important;
	}

	.mobile-flex {
		display: flex !important;
	}

	.desktop-flex {
		display: none !important;
	}
	.mobile-init {
		display: initial !important;
	}

	.desktop-init {
		display: none !important;
	}
	
	.home-page {
		.banner-title {
			font-weight: 600;
			font-size: 2rem;
			line-height: 2.5rem;
		}
	}
	// .product-action-vertical {
	// 	visibility: hidden;
	// 	opacity: 0;
	// }
}
// desktop
@include mq('md') {
	.mobile {
		display: none !important;
	}

	.desktop {
		display: block !important;
	}

	.mobile-flex {
		display: none !important;
	}

	.desktop-flex {
		display: flex !important;
	}
	.mobile-init {
		display: none !important;
	}

	.desktop-init {
		display: initial !important;
	}

	.header {
		margin: 0;
	}
}

.preloader {
	// position: absolute;
	// z-index: 6;
	// left: 0;
	// top: 0;
	// right: 0;
	// bottom: 0;
	// background: rgba(#fff, 1);
	// opacity: 0;
	// visibility: hidden;
	// transition: opacity .3s, visibility 0s .3s;
	// &:after {
	//     @include preloader(100px);
	// }
}

.content-loading .preloader {
	// transition-delay: 0s, 0s;
	// opacity: 1;
	// visibility: visible;

}


.ygag{
    .card{
        background : url('../assets/images/ygag.png');
		background-repeat: no-repeat;
		height: 256px;
		width: 397px;
		background-size: contain;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		color: white;
		padding: 10px 20px;
		.amount{
			font-size: 48px;
			font-weight:bold;
			text-align: right;
		}
		.number{
			font-size: 38px;
			font-weight:normal;
			letter-spacing: 0.15em;
			text-align: center;
		}
		.expiry{
			font-size: 14px;
			text-align: right;
			line-height: 1.5em;
		}
    }
}

@include mq('md', 'max') {
	.ygag{
		text-align: center;
		.card{
			height: 217.6px;
			width: 335px;
			color: white;
			padding: 10px 20px;
			margin: 5px auto;
			.amount{
				font-size: 42px;
			}
			.number{
				font-size: 30px;
				letter-spacing: 0.2em;
			}
		}
	}
}

.loading {
	width:100%;
	height: 300px;
    display: block;
    transition: all .5s ease-in-out;
    background: #fff;
    opacity: 1;
    visibility: visible;
    z-index: 10000;
}
.top-warning{
	width: 100%;
	text-align: center;
	position: sticky;
}
.top-warning-error{
	background-color: brown;
	p{
		color: white;
	}
}
.top-warning-reload{
	background-color: $light-color;
	p{
		color: $primary-color;
		font-weight: bold;
		button{
			margin:1rem;
		}
	}
}
#TabbyPromo{
	margin-bottom: 1rem;
}
.mobileNumber{
	direction: ltr;
}

.error {
    border: 1px solid #e02b27;
    color: #e02b27;
	padding: 5px;
	font-size: 16px;
}
.success{
	border: 1px solid #127b1a;
	color: #127b1a;
	padding: 5px;
	font-size: 16px;
}
i.icon-x{
	height: 20px;
	width: 20px;
}