/* Mobile Menu Light */
.mobile-search-container {
	background-color: #fff;
	position: absolute;
	z-index: 1;
	width: 100%;
	will-change: transform;
	transition: all 0.4s ease;
	visibility: hidden;
	opacity: 0;

	.mobile-search {
		margin: 0.5rem 0;
		padding: 0 1rem;
		display: flex;
		align-items: center;

		.form-control {
			height: 36px;
			margin-bottom: 0;
			-webkit-appearance: none;
			font-size: 1.2rem;
			line-height: 1.5;
			padding: .8rem 1.5rem;
			max-width: calc(100% - 36px);
			border-right-width: 0;
			color: #666;
			background-color: #fff;
			border-color: #e5e5e5;

			&::placeholder {
				color: #666;
			}

			&:focus {
				color: #666;
				border-color: $primary-color;
				background-color: #fff;
			}
		}

		.btn {
			font-size: 1.2rem;
			min-width: 3.6rem;
			padding: .8rem .2rem;
		}
	}
}

.search-active {
	.mobile-search-container {
		visibility: visible;
		opacity: 1;
	}
}