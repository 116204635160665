.card {
	border-radius: 0;
	margin-bottom: 0;
	border: none;
	border-top: 1px solid #ebebeb;

	&:last-child {
		border-bottom: 1px solid #ebebeb;;
	}
}

.card-header {
	position: relative;
	padding: 0;
	border: none;
	font-weight: 700;
	line-height: 1.5;
	background-color: transparent;
	margin: 0;
	@include clearfix();
}

.card-title {
	font-size: 1.8rem;
	line-height: 1.2;
	margin-bottom: 0;

	a {
		display: block;
		padding: 1.7rem 4rem 1.7rem 1.2rem;
		position: relative;
		color: $primary-color;
		border: none;
		background-color: transparent;
		outline: none !important;

		i {
			min-width: 17px;
			margin-right: 2.4rem;
		}

		&:before {
			content: '\e802';
			display: inline-block;
			position: absolute;
			right: 1.2rem;
			top: 50%;
			color: $primary-color;
			font-family: "molla";
			line-height: 1;
			transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transition: all 0.3s;
		}

		&.collapsed {
			color: #333;

			&:hover,
			&:focus {
				color: $primary-color;
			}

			&:before {
				content: '\f110';
				color: #8c8c8c;
				transform: translateY(-50%);
				-ms-transform: translateY(-50%);
			}
		}
	}
}

.card-body {
	padding: .4rem 4rem 1.8rem 1.2rem;
	border: none;
	border-radius: 0;

	.accordion-icon & {
		padding-left: 5.5rem !important;
	}
}

.accordion-plus {
	.card-title {
		a {
			&:before {
				content: '\f28e';
			}

			&.collapsed {
				&:before {
					content: '\f2c2';
				}
			}
		}
	}
}

.accordion-rounded .card {
	&:first-child {
		border-radius: .3rem .3rem 0 0;
	}

	&:last-child {
		border-radius:  0 0 .3rem .3rem;
	}
}

.card {
	&.card-box {
		.card-title {
			a {
				padding-left: 2rem;

				&:before {
					right: 2rem;
				}

				i {
					margin-right: 1.4rem;
				}
			}
		}

		.card-body {
			padding: 1rem 2rem 2rem;
		}
	}

	&.card-sm {
		.card-title {
			font-size: 1.6rem;
			letter-spacing: -.01em;

			a {
				padding-top: 2.1rem;
				padding-bottom: 2.1rem;

				&:before {
					right: 2rem;
				}
			}
		}

		.card-body {
			padding: 1rem 2rem 2rem;
		}
	}

	&.bg-light,
	&.bg-white {
		background-color: #fff !important;
		border: 1px solid #ebebeb;

		.card-title {
			a {
				background-color: #fff;
				transition: all .3s;
			}
		}
	}

	&.bg-light {
		.card-title {
			a {
				&.collapsed {
					background-color: #fafafa;
				}
			}
		}
	}
}

molla-card + molla-card .card {
    border-top: none !important;
}

@include mq('lg') {
	.card-title {
		font-size: 2rem;
	}
}