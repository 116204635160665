/* Footer */
.footer {
	width: 100%;
	font-weight: 300;
	font-size: 1.4rem;
	color: #777;
	background-color: #f5f6f9;
	border-top: 1px solid #e2e5f1;
	a {
		color: inherit;
	}

	a:hover,
	a:focus {
		color: $primary-color;
	}

	.widget-title {
		color: #333333;
		font-weight: 600;
		font-size: 1.6rem;
		letter-spacing: -.01em;
		margin-top: 0;
		margin-bottom: 1.9rem;
	}

	.accordion-footer{
		margin-top: 20px;
		.widget-title{
			margin-bottom: 0;
			border-top: .1rem solid #e1e1e1;
			a {
				display: block;
				padding: 1.7rem 0;
				position: relative;
				color: $primary-color;
				border: none;
				background-color: transparent;
				outline: none !important;
		
				i {
					min-width: 17px;
					margin-right: 2.4rem;
				}
		
				&:before {
					content: '\e802';
					display: inline-block;
					position: absolute;
					right: 1.2rem;
					top: 50%;
					color: $primary-color;
					font-family: "molla";
					line-height: 1;
					transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transition: all 0.3s;
				}
		
				&.collapsed {
					color: #333;
		
					&:hover,
					&:focus {
						color: $primary-color;
					}
		
					&:before {
						content: '\f110';
						color: #8c8c8c;
						transform: translateY(-50%);
						-ms-transform: translateY(-50%);
					}
				}
			}
		}
		.widget:last-child{
			border-bottom: .1rem solid #e1e1e1;
		}
	}
}

.footer-middle {
	border-top: none;
	padding: 5rem 0 3.5rem;
}

.footer-logo {
	margin-bottom: 2rem;
	margin-top: -.8rem;
}

.widget-about p {
	.container-fluid & {
		max-width: 540px;
	}

	&:last-of-type {
		margin-bottom: 3.2rem;
	}
}

.widget-list {
	margin-bottom: 0;

	li:not(:last-child) {
		margin-bottom: .4rem;
	}

	a {
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: .1rem;
			background-color: $primary-color;
			transform-origin: right center;
			transform: scale(0, 1);
			transition: transform .3s ease;
		}

		&:hover,
		&:focus {
			&:before {
				transform-origin: left center;
				transform: scale(1, 1)
			}
		}
	}
}

.widget-newsletter {
	form {
		padding-top: .7rem;
	}

	.form-control {
		height: 46px;
		padding-top: 1.15rem;
		padding-bottom: 1.15rem;
		background-color: #fff;
		border-right-width: 0;

		&:not(:focus) {
			border-color: #dadada;
		}
	}

	.btn {
		min-width: 46px;
		font-size: 1.5rem;
		padding: 1.1rem 1rem;

		i {
			margin: 0 !important;
		}
	}
}

.widget-about-info {
	font-weight: 400;
	font-size: 2rem;
	letter-spacing: -.01em;
	line-height: 1.3;
	padding-top: .7rem;

	.footer-payments {
		margin-top: .6rem;
	}
}

.widget-about-title {
	display: block;
	font-weight: 300;
	font-size: 1.3rem;
	color: #333333;
	letter-spacing: 0;
	margin-bottom: .4rem;
}

.widget-call {
	position: relative;
	font-size: 1.3rem;
	padding-top: 1rem;
	padding-bottom: 1rem;

	@include direction {
		#{$padding-inline-start}: 6rem;
		#{$padding-inline-end}: 0.5rem;
	}

	border: .1rem solid #e1e1e1;
	border-radius: .3rem;
	line-height: 1.25;
	max-width: 270px;
	margin: 0px auto 5px;

	i {
		display: inline-block;
		color: $dark-text;
		font-size: 3rem;
		line-height: 1;
		position: absolute;

		@include direction {
			#{$inline-start}: 2rem;
		}

		top: 50%;
		transform: translateY(-50%);
		-ms-transform: translateY(-50%);
	}

	a {
		// display: block;
		font-weight: 400;
		font-size: 1.3rem;
		color: $primary-color;

		&:hover,
		&:focus {
			color: darken($primary-color, 5%);
			box-shadow: none;
		}
	}
}

.widget-about p:last-of-type {
	margin-bottom: 1.2rem;
}

.footer-bottom {

	.container,
	.container-fluid {
		position: relative;
		display: flex;
		align-items: center;
		align-content: center;
		flex-direction: column;
		padding-top: 2.1rem;
		padding-bottom: 2.9rem;

		&:before {
			content: '';
			display: block;
			height: 1px;
			position: absolute;
			top: 0;
			left: 10px;
			right: 10px;
			background-color: #ebebeb;
		}
	}

	.container {
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
	}

	.social-icons {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem;
	}

	.social-icon {
		font-size: 1.3rem;
	}
}

.footer-copyright {
	margin-bottom: 1rem;
	text-align: center;
}

.footer-payments {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}

.footer-newsletter {
	color: #ebebeb;
	padding-top: 4.8rem;
	padding-bottom: 5.5rem;

	.heading {
		margin-bottom: 2.4rem;
	}

	.title {
		color: #fff;
		font-weight: 500;
	}

	.title-desc {
		color: inherit;
		font-size: 1.6rem;
		margin-top: .7rem;

		span {
			color: #fff;
			font-weight: 400;
		}
	}

	.form-control {
		height: 46px;
		padding: 1.25rem 2rem;
		font-size: 1.3rem;
		line-height: 1.5;
		font-weight: 300;
		color: #999999;
		background-color: #fff;
		border: none;
		border-radius: 0;
		margin-bottom: 0;
		transition: all 0.3s;

		&.form-control::placeholder {
			color: #999999;
		}
	}

	.btn {
		i:last-child {
			@include direction {
				#{$margin-inline-start}: 1rem;
			}
		}
	}
}

.footer-menu {
	display: flex;
	align-items: center;
	margin-bottom: .8rem;
	margin-left: auto;
	margin-right: auto;

	li {
		position: relative;

		&+li {
			margin-left: 2.1rem;

			&:before {
				content: '';
				display: inline-block;
				width: 1px;
				height: 1.2rem;
				position: absolute;
				left: -1.1rem;
				top: 50%;
				margin-top: -.6rem;
				background-color: #b5b5b5;
			}
		}
	}

	a {
		box-shadow: 0 1px 0 #b5b5b5;
	}
}

.col-md-6 .widget-about p,
.col-lg-6 .widget-about p {
	max-width: none;
}

/* Scroll Top Button */
#scroll-top {
	width: 5rem;
	height: 5rem;
	position: fixed;

	@include direction {
		#{$inline-end}: 50px;
	}

	bottom: 128px;
	z-index: 11;
	color: #999;
	display: none;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	visibility: hidden;
	opacity: 0;
	border-radius: 0;
	border: none;
	background-color: #fafafa;
	font-size: 3rem;
	transition: all 0.4s ease;
	transform: translateY(60px);
	-ms-transform: translateY(60px);
}

#scroll-top:hover,
#scroll-top:focus {
	color: #777;
	background-color: #eaeaea;
}

#scroll-top.show {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
}


@include mq('md') {
	#scroll-top {
		display: flex;
	}
}

@include mq('lg') {
	.footer-bottom {

		.container,
		.container-fluid {
			flex-direction: row;
		}

		.social-icons {
			margin-left: auto;
			margin-right: 0;
			margin-bottom: 0;
		}
	}

	.footer-copyright {
		margin-bottom: 0;

		@include direction {
			text-align: $inline-start;
		}

	}

	.footer-payments {
		@include direction {
			#{$margin-inline-end}: 0;
		}
	}

	.footer-menu {
		margin-bottom: 0;
		margin-left: .6rem;
		margin-right: 0;
	}
}

@include mq('xl') {
	.footer-bottom {
		.container-fluid {
			&:before {
				left: 30px;
				right: 30px;
			}
		}
	}
}

@include mq('xxl') {
	.footer-bottom {
		.container-fluid {
			&:before {
				left: 70px;
				right: 70px;
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
	.footer {
		.widget-about {
			.social-icon:not(:last-child) {
				margin-right: .4rem;
			}
		}
	}
}

@include mq('sm', 'max') {
	.widget-about-info {
		.col-sm-6:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
}

.sticky-footer {
	background: white;
	height: 56px;
	padding-bottom: 5px;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
	display: flex;
	border-top: solid 0.5px $light-text;
	margin-top: 5px;
	align-items: center;
	justify-content: center;
	color: $light-text;
	font-size: 10px;

	.tab {
		flex: 1 1 0%;
		flex-direction: column;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 7px 0;
		display: flex;
		fill: $primary-color;

		i {
			color: $light-text;
			font-size: 26px;
			line-height: .9;
		}

		span {
			height: 20%;
			vertical-align: bottom;
		}

		.cart-quantity {
			height: 15px;
			font-size: 10px;
			padding: 0 5px;
			border-radius: 1000px;
			position: absolute;
			top: 3px;
			background: $primary-color;
			color: #fff;
			font-weight: bold;

			@include direction {
				#{$margin-inline-start}: 8px;
				#{$margin-inline-end}: -18px;
			}
		}
	}

	.tab.tab-active {
		color: $primary-color;
		fill: $primary-color;
		// border-top: solid 2px $primary-color;

		.cart-quantity {
			background: $primary-color;
			color: #fff;
		}

		i {
			color: $primary-color;
		}
	}
}