molla-image {
    position: relative;
    display: block;
    background-color: #f4f4f4;

    img.ng-lazyloaded {
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        // width: calc(100% - 20px);
        // height: calc(100% - 20px);
        // margin: 10px;
    }

    &.h-100 {
        img {
            height: 100%;
        }
    }

    &.w-100 {
        img {
            width: 100%;
        }
    }
    
    img.ng-lazyloading {
        max-height: 1px;
        height: 0px;
    }
    
    img.ng-lazyloaded {
        animation-name: fadeIn;
        animation-duration: .3s;
    }
}