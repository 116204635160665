@import '../config/direction';

// Product Details pages
.product-details-top {
	margin-bottom: 1rem;
}

.product-gallery {
	margin-bottom: 2rem;
}

.product-main-image {
	position: relative;
	margin-bottom: 1rem;

	img {
		max-width: none;
		width: 100%;
		height: 100%;
		object-fit: cover;
		background-color: #f4f4f4;
	}
}

.ngxImageZoomContainer {
	background: #f4f4f4;
	position: absolute !important;
	width: 100% !important;
	height: 100% !important;
	left: 0;
	bottom: 0;
	top: 0;
	cursor: crosshair;
}

.product-main-image .zoom-image {
	background-color: #f4f4f4;

	>div {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		img {
			object-fit: cover;
		}
	}
}

.product-gallery-carousel .product-gallery-image>div {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.btn-product-gallery {
	position: absolute;
	right: 2rem;
	bottom: 2rem;
	z-index: 49;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 4rem;
	height: 4rem;
	color: #777;
	font-weight: normal;
	font-size: 2rem;
	line-height: 1;
	background-color: #fff;
	box-shadow: 2px 6px 16px rgba(51, 51, 51, .05);
	transition: all .35s ease;

	&:hover,
	&:focus {
		color: #fff;
		background-color: $primary-color;
	}
}

.product-details {
	margin-bottom: 2rem;

	.product-title {
		font-weight: 400;
		font-size: 2.4rem;
		letter-spacing: -.025em;
		margin-bottom: 1.2rem;
		margin-top: -.5rem;
		padding-right: 1rem;
	}

	.ratings-container {
		margin-bottom: 1.3rem;
	}

	.product-price {
		font-size: 2.4rem;
		font-weight: bold;
		span{
			font-weight: normal;
			font-size: initial;
			color: $body-text;
		}
	}

	.new-price {
		margin-right: 1rem;
	}

	.product-content {
		margin-bottom: 1.6rem;

		p:last-child {
			margin-bottom: 0;
		}
	}

	.product-countdown {
		position: static;
		left: auto;
		right: auto;
		bottom: auto;
		top: auto;
		max-width: 260px;
		margin-left: -5px;
		margin-bottom: 2rem;

		.countdown-section {
			background-color: #fafafa;
		}
	}

	.product-nav-dots a,
	.product-nav-dots span {
		width: 2rem;
		height: 2rem;
	}

	.product-nav-dots a {
		&.disabled {
			opacity: .3;
			cursor: not-allowed;
			pointer-events: none;

			&:hover {
				box-shadow: none;
			}
		}
	}

	.product-cat {
		font-weight: 400;
		font-size: 1.4rem;

		span {
			margin-right: .7rem;
		}

		a {
			color: $primary-color;

			// color: #666;
			box-shadow: none;
			transition: all .35s ease;

			&:hover,
			&:focus {
				color: $primary-color;
				box-shadow: 0 1px 0 $primary-color;
			}
		}
	}

	.product-size {
		flex-flow: row wrap;
		padding-top: 0;
		margin-bottom: 0;

		a {
			color: #666;
			width: 4rem;
			height: 4rem;
			font-weight: 400;
			font-size: 1.4rem;
			letter-spacing: -.01em;
			border: 1px solid #d7d7d7;
			background-color: transparent;
			border-radius: 0;
			margin-right: 1rem;

			&.active,
			&:hover,
			&:focus {
				color: $primary-color;
				border-color: $primary-color;
				background-color: transparent;
			}

			&.disabled {
				color: #dadada;
				border-color: #ebebeb;
				background-color: transparent;
			}
		}
	}

	.btn-product {

		&:hover,
		&:focus {
			span {
				box-shadow: none;
			}
		}
	}
	.brandImg{
        height: 28px;
		display: inline-block;
    }

	// goldenWarranty
	.goldenWarranty {
		display: flex;
		margin-bottom: 10px;
		align-items: center;

		img {
			width: 60px;
			height: 60px;
			margin: 0px;
			@include direction {
				#{$margin-inline-end}: 20px;
			}
		}

		label {
			margin: 0px;
		}

		.gold-form {
			display: flex;

			label {
				cursor: pointer;
				display: flex;
				padding: 2px 10px 2px 0px;

				span {
					margin: 0px 5px;
				}

				input {
					line-height: 26px;
					min-width: 18px;
					min-height: 18px;
				}
			}
		}

		.terms {
			font-size: 13px;
		}
	}
	// .installmentsAds{
	// 	border: 1px solid #ccc;
	// 	padding:5px;
	// 	width: fit-content;
	// 	margin-bottom: 1rem;
	// 	font-size:1.3rem;
	// 	img{
	// 		height: 20px;
	// 		display: inline-block;
	// 	}
	// }
	.btn-cart{
		max-width: 150px;
	}
}

.details-filter-row {
	display: flex;
	align-items: center;
	flex-flow: row wrap;
	margin-bottom: 1rem;

	.form-control {
		font-weight: 400;
		margin-bottom: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		background-color: transparent;
		border-color: #d7d7d7;
		min-width: 131px;
	}

	.select-custom {
		margin-bottom: 0;
		margin-right: 3.9rem;
		z-index: 1;

		.form-control {
			padding-right: 3rem;
		}

		&::after {
			font-size: 1rem;
			right: .9rem;
		}
	}

	label {
		display: inline-block;
		width: 67px;
		font-weight: 400;
		font-size: 1.4rem;
		margin-bottom: 0;
	}

	.product-nav {
		margin-bottom: 0;
	}

	.product-nav-thumbs {
		padding-top: 0;
	}
}

.size-guide {
	color: #777;
	display: inline-flex;
	align-items: center;

	i {
		font-size: 1.8rem;
		margin-right: .7rem;
	}

	&:hover,
	&:focus {
		color: $primary-color;
	}
}

.product-details-quantity {
	max-width: 131px;
	flex: 0 0 131px;
	@include direction {
		#{$margin-inline-end}: 1rem;
	}
	.form-control {
		-moz-appearance: textfield;
		padding-left: 2.6rem;
		padding-right: 2.6rem;
		text-align: center;
		margin-bottom: 0;
	}
}



	.btn-cart {
		padding: 1rem;
		color: $primary-color;
		border: 01px solid $primary-color;
		// max-width: 200px;
		span {
			font-size: 1.4rem;
			color: inherit;
			text-transform: uppercase;
			border: 0;
			transition: .15s;
		}

		&:before {
			margin-right: .4rem;
		}

		&:hover,
		&:focus {
			color: #fff;
			border-color: $primary-color;
			background-color: $primary-color;
		}
	}


@include only_for_edge () {
	.btn-cart:before {
		margin-bottom: 1px;
	}
}

.details-action-wrapper {
	display: flex;
	align-items: center;
	margin: 1rem ;
	

	.btn-product {
		padding-top: 0;
		padding-bottom: 0;
		white-space: nowrap;
		align-self: stretch;
		line-height: 1.4;

		&:before {
			margin-right: 1rem;
		}

		&.btn-wishlist:before {
			margin-top: -.2rem;
		}

		span {
			font-size: 1.4rem;
		}

		&+.btn-product {
			padding-left: 1.3rem;
			margin-left: 1.6rem;
		}
	}
}

.product-details-footer {
	color: #777;
	display: flex;
	font-weight: 400;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: .7rem;
	border-top: 1px solid #ebebeb;
	padding-bottom: .7rem;
	justify-content: space-between;

	.social-icon {
		margin-bottom: 0;
	}

	&>* {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.icon-certificate{
		color: #ecc700;
	}
	.icon-certificate::before{
		font-size: 1.7rem;
	}
}

@include mq('lg') {
	.container-fluid {
		.product-details-top {
			.col-lg-7 {
				-ms-flex: 0 0 56.5%;
				flex: 0 0 56.5%;
				max-width: 56.5%;
			}

			.col-lg-5 {
				-ms-flex: 0 0 43.5%;
				flex: 0 0 43.5%;
				max-width: 43.5%;
			}
		}
	}
}

@include mq('1700px') {
	.container-fluid {
		.btn-cart {
			min-width: 238px;
		}

		.details-filter-row .form-control {
			min-width: 171px;
		}

		.product-details-quantity {
			max-width: 171px;
			flex: 0 0 171px;
		}
	}

	@include mq('1775px', 'max') {
		.details-fullwidth {
			.details-action-wrapper {
				margin-top: 1.5rem;
				margin-left: 0;
			}

			.btn-cart {
				margin-right: 4rem;
			}
		}
	}
}

.product-details-tab {
	margin-bottom: 5rem;

	.nav.nav-pills {
		.nav-link {
			color: #333333;
			font-size: 1.6rem;
			padding: .55rem 3rem;
			border-bottom-width: .2rem;
			text-transform: capitalize;

			&:hover,
			&:focus {
				color: $primary-color;
				border-bottom-color: $primary-color;
			}
		}

		.nav-item {
			&+.nav-item {
				margin-left: 2rem;
			}
		}
	}

	.tab-content {
		border: 1px solid #dadada;
		margin-top: -1px;
		border-radius: .3rem;

		.tab-pane {
			padding: 2rem .5rem;
		}
	}

	.reviews .review:last-child {
		border-bottom: none;
	}
}

.product-desc-content {
	h2 {
		font-weight: 400;
		font-size: 2.4rem;
		letter-spacing: -.01em;
		margin-bottom: 1.8rem;
	}

	h3 {
		font-weight: 400;
		font-size: 1.6rem;
		letter-spacing: -.01em;
		margin: 1.8rem 0;
	}

	ul {
		color: #333333;

		li {
			position: relative;
			@include direction {
				#{$padding-inline-start}: 1.3rem;
			}

			&:before {
				content: '•';
				position: absolute;
				display: block;
				top: 0;
				@include direction {
					#{$inline-start}: .2rem;
				}
			}
		}
	}

	a {
		// color: #333;
		border-bottom: 1px solid #b8b8b8;
		transition: all .35s ease;

		&:hover,
		&:focus {
			color: $primary-color;
			border-bottom-color: $primary-color;
		}
	}

	blockquote {
		color: #333;
		font-style: normal;
		font-weight: 400;
		font-size: 2.4rem;
		line-height: 1.25;
		letter-spacing: -.01em;
		margin-bottom: 1.6rem;

		p {
			margin-bottom: 0;
		}

		cite {
			font-weight: 300;
			font-size: 1.4rem;
			letter-spacing: 0;
		}
	}

	.spec__row {
		padding: 8px 12px;
		display: flex;
		border-bottom: 1px solid #ebebeb;

		&:first-of-type {
			border-top: 1px solid #ebebeb;
		}

		.spec__name {
			color: #999;
			#{$padding-inline-end}: 30px;
			flex-shrink: 0;
			width: 40%;
		}

		.spec__value {}
	}
}

.reviews {
	h3 {
		font-size: 1.6rem;
		letter-spacing: -.01em;
		margin-bottom: 2.3rem;
	}
}

.review {
	padding-bottom: 1.3rem;
	margin-bottom: 2rem;
	border-bottom: 1px solid #ebebeb;

	&:last-child {
		margin-bottom: 0;
	}

	.col-auto {
		width: 120px;
		padding-right: 2rem;
	}

	h4 {
		color: #333;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 1.25;
		letter-spacing: -.01em;
		margin-bottom: .8rem;

		a {
			color: inherit;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}

	.ratings-container {
		padding-top: .7rem;
		margin-bottom: .5rem;
	}

	.review-date {
		color: #ccc;
	}
}

.review-content {
	margin-bottom: .8rem;
}

.review-action {
	font-size: 1.2rem;

	a {
		color: #777;
		transition: all .35s ease;

		&:hover,
		&:focus {
			color: $primary-color;
			box-shadow: 0 1px 0 $primary-color;
		}
	}

	a+a {
		margin-left: 1.6rem;
	}

	i {
		font-size: 1.4rem;
		margin-right: .6rem;
	}
}


/* Product Centered */
.product-details-centered {
	text-align: center;

	.ratings-container,
	.product-price,
	.details-filter-row {
		justify-content: center;
	}

	.product-content {
		max-width: 470px;
		margin-left: auto;
		margin-right: auto;
	}

	.details-row-size .select-custom {
		margin-right: 2rem;
	}

	.details-filter-row {
		label {
			width: auto;
			margin-right: 1rem;
		}

		.form-control {
			min-width: 104px;
		}
	}



		.btn-cart {
			min-width: 218px;
		}
	

	.details-action-wrapper {
		margin-left: 0;

		.btn-product+.btn-product {
			border-left-width: 0;
		}
	}

	.product-details-quantity {
		max-width: 100px;
	}
}

.details-action-col {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	.product-details-quantity {
		margin-right: 2rem;
	}

	label {
		display: inline-block;
		width: 67px;
		font-weight: 400;
		font-size: 1.4rem;
		margin-bottom: 0;
	}

	&>label,
	&>molla-quantity-input,
	&>.btn-cart {
		margin-bottom: 2.2rem;
	}
}


/* Product Extended */
.product-details-extended {
	.nav.nav-pills {
		border-top: 1px solid #ebebeb;

		.nav-item.show .nav-link {
			border-color: $primary-color;
		}

		.nav-link {
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
			border-top: .2rem solid transparent;
			border-bottom: none;
			margin-top: -1px;
			// border-color: $primary-color;

			&.active,
			&:hover,
			&:focus {
				border-color: $primary-color;
			}
		}
	}

	.tab-content {
		border: none;

		.tab-pane {
			padding: 0;
		}
	}
}

.product-desc-row {
	display: flex;
	align-items: center;
	padding-top: 5rem;
	padding-bottom: 5rem;
	min-height: 500px;

	&.text-white {

		h2,
		h3,
		h4 {
			color: inherit;
		}
	}
}


/* Product Gallery */
// .gallery .ngxImageZoomContainer {
//     width: auto !important;
//     height: auto !important;
//     cursor: crosshair;
// }

.product-details-separator {
	margin-bottom: 6rem;

	.details-filter-row {
		label {
			margin-right: 2.4rem;
		}

		.product-size {
			margin-right: 2rem;
		}
	}

	@include mq('md') {
		[class*="col-"] {
			padding-top: 1rem;
		}

		[class*="col-"]+[class*="col-"]:before {
			content: '';
			width: 1px;
			display: block;
			height: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			background-color: #ebebeb;
		}
	}
}

.details-footer-col {
	padding-top: 0;
	padding-bottom: 0;
	flex-direction: column;
	align-items: flex-start;
	border-top: none;

	&>* {
		padding-top: 0;
		padding-bottom: 0;
	}

	.product-details-centered & {
		align-items: center;
	}

	.product-cat {
		margin-bottom: 2.6rem;
	}

	.social-icons {
		margin-left: 0;
	}
}

/* Sticky Info */
.product-details-accordion {
	.card {
		border: .1rem solid #ebebeb;

		.card-title {
			font-weight: 400;

			span {
				background-color: #fafafa;

				&:not(.collapsed) {
					background-color: #fff;
				}
			}
		}

		.card-body {
			padding-top: 0;

			p:last-child {
				margin-bottom: .2rem;
			}
		}
	}
}

/* Sticky bar */
.sticky-bar {
	display: none;
}

.nav-none .owl-nav {
	display: none;
}
.gift-header{
	color: green;
	margin-top: 2rem;
}
.gift-card{
	display: flex;
	border: .5px solid #0080002e;
	padding: 5px;
	margin-bottom: 5px;
	.gift-title{
		color: green;
		font-size: 1.3rem;
		font-weight: bold;
		@include direction {
			#{$padding-inline-start}: 10px;
		}
		width: 100%;
		.name{
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			// font-weight: bold;
			overflow: hidden;
		}
		.details{
			color: green;
			display: block;
			span{
				font-weight: normal;
				padding: 10px 10px 0px 0px;
				text-transform: uppercase;
				font-size: 1.5rem;
			}
			.gifts-actions{
				// @include direction {
				// 	float: #{$inline-end};
				// }
				a{
					@include direction {
						#{$padding-inline-end}: 2rem;
					}
					color:black;
					span{
						font-size: 1.2rem;
					}
				}
				a::before{
					font-family: "molla";
					font-size: 18px;
					@include direction {
						#{$margin-inline-end}: 10px;
					}
				}
				a:hover{
					color: $primary-color;
					font-weight: 400;
				}
				.btn-cart-o::before{
					content: "\e812";
				}
			}
		}
	}
	img{
		width: 60px;
		height: 60px;
		border-radius: 50%;
		border: 1px solid #0080002e;
		margin: 2px;
    	padding: 2px;
	}
}
.gift-card-selected{
	border: .5px solid green;
	background: #0080000f;
}

@include mq('lg') {
	.sticky-bar {
		display: block;
		width: 100%;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 500;
		animation-name: fixedFooter;
		animation-duration: .4s;
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-color: #fff;
		box-shadow: -15px 0 43px rgba(51, 51, 51, .15);

		.row {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		.col-6 {
			display: flex;
			align-items: center;
		}

		.product-media {
			width: 60px;
			margin-right: 2rem;
			margin-bottom: 0;
			flex-shrink: 0;
		}

		.product-title {
			font-size: 1.6rem;
			margin-bottom: 0;
			letter-spacing: -.025em;
		}

		.product-price {
			font-size: 1.6rem;
			margin-bottom: 0;
			margin-right: 2rem;
		}

		.product-details-quantity {
			margin-right: 2rem;
			max-width: 100px;
		}

		.btn-cart {
			min-width: 200px;
			margin-right: 2rem;
		}

		.btn-product+.btn-product {
			border: none;
		}

		.btn-wishlist {
			font-size: 1.8rem;
			margin-right: 2rem;

			span {
				display: none;
			}

			&:before {
				margin-right: 0;
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.details-action-wrapper {
		margin-left: 1rem;
	}

	.product-details-sidebar .product-details-quantity {
		max-width: 91px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1180px) {
	.IE-details-action-wrapper {
		margin-left: 0;
		margin-top: 1.5rem;
	}
}

@media screen and (min-width: 992px) and (max-width: 1113px) {
	.details-fullwidth {
		.details-action-wrapper {
			margin-top: 1.5rem;
			margin-left: 0;
		}

		.btn-cart {
			margin-right: 4rem;
		}
	}
}

@media screen and (min-width: 1200px) and (max-width: 1447px) {
	.details-fullwidth {
		.details-action-wrapper {
			margin-top: 1.5rem;
			margin-left: 0;
		}

		.btn-cart {
			margin-right: 4rem;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {

	.details-action-wrapper {
		margin-left: 0;
		margin-top: 1.5rem;
	}

	.btn-cart {
		min-width: 198px;
	}

	.product-details-tab {
		.nav.nav-pills .nav-item {
			&+.nav-item {
				margin-left: 1rem;
			}

			.nav-link {
				padding-left: 1.5rem;
				padding-right: 1.5rem;
			}
		}
	}
}

@include mq('xl', 'max') {
	.sidebar-product .btn {
		width: 85%;
	}
}

@include mq('lg', 'max') {
	.col-lg-3>.sticky-content {
		position: static !important;
	}
}

@include mq ('md', 'max') {
	.product-desc-row {
		min-height: 380px;
	}

	.product-desc-content h2,
	.product-details .product-title {
		font-size: 2.2rem;
	}

	.product-gallery-separated {
		margin-bottom: 3rem;
	}

	.product-details-tab .nav.nav-pills .nav-link {
		font-size: 1.5rem;
		padding: 0.55rem 1rem;
	}
}

@include mq('sm', 'max') {
	.breadcrumb-nav .d-flex {
		flex-direction: column;
		align-items: flex-start !important;
	}

	.product-details-tab .nav.nav-pills .nav-item+.nav-item {
		margin-left: 0;
	}

	.product.no-span .product-action span {
		display: none;
	}
}

@media screen and (max-width: 580px) {

	.product-details-quantity {
		max-width: 100px;
	}
	
	.details-filter-row .form-control {
		min-width: auto;
	}
	.product-details-footer {
		flex-direction: column;
		align-items: flex-start;

		.social-icons {
			margin-left: 0;
		}
	}

	.review {
		.row {
			flex-direction: column;
		}
	}

	.details-filter-row .select-custom {
		margin-right: 1.5rem;
	}

	.product-details {
		.goldenWarranty {
			img {
				width: 50px;
				height: 50px;
				margin: 0px;
				@include direction {
					#{$margin-inline-end}: 10px;
				}
			}
		}
		// .installmentsAds{
		// 	font-size: 1.3rem;
    	// 	width: 100%;
		// 	img{
		// 		height: 18px;
		// 	}
		// }
	}
}

@include mq('1920px', 'max') {
	.details-action-col {
		flex-wrap: nowrap;
	}
}

@include mq('md', 'max') {
	.product-main-image {
		width: 100%;
	}

	.product-details-centered {
		margin-bottom: 1rem;
	}
}

@include mq("xs", "max") {
	.product-media {

		molla-count-down,
		.product-countdown-container {
			display: none;
		}
	}
}

@include mq("425px", "max") {
	.product.product-list {
		.ratings-container {
			margin-bottom: .5rem;
			display: flex;
		}

		.ratings-text {
			display: none;
		}

		.product-price {
			margin-bottom: .7rem;
		}

		.product-action {
			display: block;
			margin-bottom: .7rem;
		}

		.btn-product+.btn-product {
			border-left: 0;
		}

		.btn-product {
			margin-left: 0;
		}

		.btn-product.btn-quickview,
		.btn-product.btn-wishlist {
			flex: 1 1 100%;
			max-width: 100%;
			padding-left: 0;
			justify-content: flex-start;
		}
	}

	.product-details-centered {

		.size-guide,
		.btn-compare {
			display: none;
		}
	}
}

@include mq('375px', 'max') {
	.sticky-content {
		flex-wrap: nowrap;
	}

	.product-list.product {
		.btn-product {
			margin-left: 0 !important;
		}

		.product-price {
			margin-bottom: .3rem;
		}

		.btn-product.btn-cart {
			font-size: 15px;

			span {
				font-size: 11px;
			}
		}
	}
}