@import '../config/variables';


//nav
$nav-full-width:				30px !default;
$nav-full-height:			100% !default;
$nav-full-color: 			$primary-color !default;
$nav-full-color-hover:		$primary-color !default;
$nav-full-font-size: 		20px !default;
$nav-full-rounded: 			0 !default;
$nav-full-background: 		rgba(255, 255, 255, .75) !default;
$nav-full-background-hover:	rgba(255, 255, 255, 1) !default;
$nav-full-disabled-opacity: 	0 !default;

//dots
$dot-full-width:				8px !default;
$dot-full-active-width: 		18px !default;
$dot-full-height:			8px !default;
$dot-full-rounded:			30px !default;
$dot-full-margin: 			5px 6px !default;
$dot-full-border:			$primary-color !default;
$dot-full-background:		transparent !default;
$dot-full-border-active:		$primary-color !default;
$dot-full-background-active:	$primary-color !default;

.owl-theme {
	// Styling Next and Prev buttons
	.owl-nav {
		margin-top: 0;
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		[class*=owl-] {
			position: absolute;
			top: 0;
			z-index: 99;
			display: flex;
			align-items: center;
			justify-content: center;
			width: $nav-full-width;
			height: $nav-full-height;
			margin: 0;
			color: $nav-full-color;
			font-size: $nav-full-font-size;
			line-height: 1;
			padding: 0;
			text-align: center;
			background: $nav-full-background;
			cursor: pointer;
			border-radius: $nav-full-rounded;
			transition: all 0.3s ease;
			border: 0.1rem solid #ebebeb;
			i {
				margin-top: .1rem;
			}

			&:hover,
			&:focus {
				color:$nav-full-color-hover;
				background: $nav-full-background-hover;
				text-decoration: none;
				outline: none !important;
			}

			&:before {
				content: '';
				display: block;
				width: 10px;
				height: 100%;
				position: absolute;
				@include direction {
				    #{$inline-start}: 100%;
				}
				top: 0;
				// border-radius: 20px 0 0 20px;
				// border-radius: if($direction ==ltr, 20px 0 0 20px, 20px 20px 0 0);
				// background-size: contain;
				// background-position: center center;
				// background-repeat: no-repeat;
				// background-image: if($direction ==ltr, url('../../assets/images/nav-prev-shadow.png'), url('../../assets/images/nav-next-shadow.png'));
				
			}
		}
		.disabled {
			opacity: $nav-full-disabled-opacity;
			pointer-events: none;
			cursor: default;
		}

		.owl-prev {
			@include direction {
				#{$inline-start}: 0;
			}
		}

		.owl-next {
			@include direction {
				#{$inline-end}: 0;
			}

			&:before {
				@include direction {
				    #{$inline-start}: auto;
				    #{$inline-end}: 0;
				}
				// background-image: if($direction ==ltr, url('../../assets/images/nav-next-shadow.png'), url('../../assets/images/nav-prev-shadow.png'));
			}
		}
	}

	&.carousel-with-shadow {
		.owl-stage-outer {
			@include direction {
				#{$padding-inline-start}: 0;
				#{$margin-inline-start}: 0;
				#{$padding-inline-end}: 0;
				#{$margin-inline-end}: 0;
			}
		}

		.owl-nav {
			[class*='owl-'] {
				height: calc( 100% - 20px );
			}
		}

		.owl-prev, .owl-next {
			top: 1.5rem;
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 0;
	}

	.owl-dots {
		position: absolute;
		@include direction {
			#{$inline-start}: 0;
			#{$inline-end}: 0;
		}
		bottom: -4rem;
		line-height: 0;
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: $dot-full-width;
				height: $dot-full-height;
				margin: $dot-full-margin;
				border: .1rem solid $dot-full-border;
				background: $dot-full-background;
				display: block;
				-webkit-backface-visibility: visible;
				transition: all .3s ease;
				border-radius: $dot-full-rounded;
			}

			&:hover {
				span {
					border-color: $dot-full-border-active;
					background: $dot-full-background-active;
				}
			}

			&.active {
				span {
					width: $dot-full-active-width;
					border-color: $dot-full-border-active;
					background: $dot-full-background-active;
				}
			}
		}
	}


	&.owl-nav-dark {
		.owl-nav {
			[class*='owl-'] {
				color: #333;

				&:hover,
				&:focus {
					color:$nav-full-color-hover;
					background: $nav-full-background-hover;
				}
			}
		}
	}

	.bg-light & {
		.owl-nav {
			[class*='owl-'] {
				&:hover,
				&:focus {
					background: #f8f8f8;
				}
			}
		}
	}
}

.main-img-slider {
    .owl-theme .owl-nav [class*='owl-'] {
		width: 4rem;
		height: 4rem;
		font-size: 2.2rem;
		margin-top: -2rem;
		top: 50%;
	}
    .owl-dots {
        bottom: 20px;

        .owl-dot {
            cursor: pointer;
            span{
                width: 15px;
                height: 15px;
                margin: 5px 6px;
                border-radius: 30px;
            }
            &.active {
				span {
					width: 25px;
                    // border-color: #f8f8f8;
                    // background: #f8f8f8;
				}
			}
        }
    }
}