@charset "UTF-8";
/*-------------------- TABLE OF CONTENTS --------------- */

/*-------------------- 1.    GENERAL STYLES ------------------- */
/*-------------------- 2.    HEADERS ------------------- */
/*-------------------- 2.1   Menu ------------------- */
/*-------------------- 2.2   Vertical Menu ------------------- */
/*-------------------- 2.3   Sticky Header ------------------- */
/*-------------------- 2.4   Mobile Menu ------------------- */
/*-------------------- 3.    FOOTERS ------------------- */

/*-------------------- 4.    ELEMENTS ------------------- */
/*-------------------- 4.1.  Page Header --------------------*/
/*-------------------- 4.2.  BreadCrumbs --------------------*/
/*-------------------- 4.3.  Titles --------------------*/
/*-------------------- 4.4.  Icon boxes --------------------*/
/*-------------------- 4.5.  Cards --------------------*/
/*-------------------- 4.6.  Tabs --------------------*/
/*-------------------- 4.7.  Buttons --------------------*/
/*-------------------- 4.8.  Products --------------------*/
/*-------------------- 4.9.  Banners --------------------*/
/*-------------------- 4.10. Banners Video --------------------*/
/*-------------------- 4.11. Banners Category --------------------*/
/*-------------------- 4.12. Social Icons --------------------*/
/*-------------------- 4.13. Testimonials --------------------*/

/*-------------------- 4.14. Forms --------------------*/
/*-------------------- 4.15. Call to Actions --------------------*/
/*-------------------- 4.16. Tables --------------------*/
/*-------------------- 4.17. Counters --------------------*/
/*-------------------- 4.18. Pagination --------------------*/
/*-------------------- 4.19. Modals --------------------*/
/*-------------------- 4.20. Alerts --------------------*/
/*-------------------- 4.21. Instagram Feeds --------------------*/
/*-------------------- 4.22. Elements List --------------------*/
/*-------------------- 4.23. Overlay --------------------*/
/*-------------------- 4.24. Countdown --------------------*/
/*-------------------- 4.25. LightBox --------------------*/
/*-------------------- 4.26. Slider --------------------*/
/*-------------------- 4.27. Toast --------------------*/
/*-------------------- 4.28. Skeleton --------------------*/
/*-------------------- 4.29. Animations --------------------*/
/*-------------------- 4.30. LazyImageLoad --------------------*/

/*-------------------- 5.    PAGES --------------------*/
/*-------------------- 5.1.  Home --------------------*/
/*-------------------- 5.2.  Category --------------------*/
/*-------------------- 5.3.  Product Pages --------------------*/
/*-------------------- 5.4.  Blog --------------------*/
/*-------------------- 5.5.  Single/Blog Post --------------------*/
/*-------------------- 5.6.  Portfolio --------------------*/
/*-------------------- 5.7.  About --------------------*/
/*-------------------- 5.8.  About 2 --------------------*/
/*-------------------- 5.9.  Contact --------------------*/
/*-------------------- 5.10. Contact 2 --------------------*/
/*-------------------- 5.11. Login --------------------*/
/*-------------------- 5.12. Category Banner Page --------------------*/
/*-------------------- 5.13. Checkout --------------------*/
/*-------------------- 5.14. Wishlist --------------------*/
/*-------------------- 5.15. Cart --------------------*/
/*-------------------- 5.16. Dashboard --------------------*/
/*-------------------- 5.17. 404 --------------------*/
/*-------------------- 5.18. Coming Soon --------------------*/

/*-------------------- 6.   SPACING & HELPERS    --------------------*/
/*-------------------- 7.   FONT ICONS   --------------------*/

/*-------------------- 8.   HomePage    --------------------*/

/*-------------------- END of TABLE OF CONTENTS  ---------- */

/* Main CSS File */
@import 'mixins/clearfix';
@import 'mixins/breakpoints';
@import 'mixins/lazy';
@import 'mixins/buttons';
@import 'mixins/preloader';

@import 'config/direction';
@import 'config/variables';

/*-------------------- 1.General Styles ---------- */
@import 'base/base';
@import 'base/type';
@import 'base/layout';

/*-------------------- 2.Headers --------------------*/
@import 'base/headers/header';

/*-------------------- 2.1.Menu --------------------*/
@import 'base/headers/menu';

/*-------------------- 2.2.Vertical Menu --------------------*/
@import 'base/headers/vertical-menu';

/*-------------------- 2.3.Sticky Header --------------------*/
@import 'base/headers/sticky-header';

/*-------------------- 2.4.Mobiel Menu --------------------*/
@import 'base/headers/mobile-menu';
@import 'base/headers/mobile-menu-light';
@import 'base/headers/mobile-search';

/*-------------------- 3.Footer --------------------*/
@import 'base/footer';

/*-------------------- 4.Elements --------------------*/
/*-------------------- 4.1.Page Header --------------------*/
@import 'elements/page-header';

/*-------------------- 4.2.BreadCrumbs --------------------*/
@import 'elements/breadcrumb';

/*-------------------- 4.3.Titles --------------------*/
@import 'elements/titles';

/*-------------------- 4.4.Icon boxes --------------------*/
@import 'elements/icon-boxes';

/*-------------------- 4.5.Cards --------------------*/
@import 'elements/cards';

/*-------------------- 4.6.Tabs --------------------*/
@import 'elements/tabs';

/*-------------------- 4.7.Buttons --------------------*/
@import 'elements/buttons';

/*-------------------- 4.8.Products --------------------*/
@import 'elements/products';

/*-------------------- 4.9.Banners --------------------*/
@import 'elements/banners';

/*-------------------- 4.10.Banners Video --------------------*/
@import 'elements/banners-video';

/*-------------------- 4.11.Banners Category --------------------*/
@import 'elements/banners-category';

/*-------------------- 4.12.Social Icons --------------------*/
@import 'elements/social-icons';

/*-------------------- 4.13.Testimonials --------------------*/
@import 'elements/testimonials';

/*-------------------- 4.14.Forms --------------------*/
@import 'elements/forms';

/*-------------------- 4.15.Call to Actions --------------------*/
@import 'elements/cta';

/*-------------------- 4.16.Tables --------------------*/
@import 'elements/tables';

/*-------------------- 4.17. Counters --------------------*/
@import 'elements/counters';

/*-------------------- 4.18.Pagination --------------------*/
@import 'elements/pagination';

/*-------------------- 4.19.Modals --------------------*/
@import 'elements/modals';

/*-------------------- 4.20.Alerts --------------------*/
@import 'elements/alerts';

/*-------------------- 4.21.Instagram Feeds --------------------*/
@import 'elements/instagram-feed';

/*-------------------- 4.22.Elements list --------------------*/
@import 'elements/list';

/*-------------------- 4.23.Overlay --------------------*/
@import 'elements/overlay';

/*-------------------- 4.24.Countdown --------------------*/
@import 'elements/countdown';

/*-------------------- 4.25.LightBox --------------------*/
@import 'elements/image-lightbox';

/*-------------------- 4.26.Slider --------------------*/
@import 'elements/slider';

/*-------------------- 4.27.Toast --------------------*/
@import 'elements/toast';

/*-------------------- 4.28.Skeleton --------------------*/
@import 'elements/skeleton';

/*-------------------- 4.29.Animations --------------------*/
@import 'elements/animations';

/*-------------------- 4.30.Lazy --------------------*/
@import 'elements/lazy-load';

/*-------------------- 4.31.Grid --------------------*/
@import 'elements/grid';

/*-------------------- 4.32.Lightbox --------------------*/
@import 'elements/lightbox';


/*-------------------- 5.Pages --------------------*/
/*-------------------- 5.1.Home --------------------*/
@import 'pages/home';

/*-------------------- 5.2.Category --------------------*/
@import 'pages/category';

/*-------------------- 5.3.Product Pages --------------------*/
@import 'pages/product';

/*-------------------- 5.4.Blog --------------------*/
@import 'pages/blog';

/*-------------------- 5.5.Single/Blog Post --------------------*/
@import 'pages/single';

/*-------------------- 5.6.Portfolio --------------------*/
@import 'pages/portfolio';

/*-------------------- 5.7.About --------------------*/
@import 'pages/about';

/*-------------------- 5.8.About 2 --------------------*/
@import 'pages/about-2';

/*-------------------- 5.9.Contact --------------------*/
@import 'pages/contact';

/*-------------------- 5.10.Contact 2 --------------------*/
@import 'pages/contact-2';

/*-------------------- 5.11.Login --------------------*/
@import 'pages/login';

/*-------------------- 5.12.Category Banner Page --------------------*/
@import 'pages/category-banner-page';

/*-------------------- 5.13.Checkout --------------------*/
@import 'pages/checkout';

/*-------------------- 5.14.Wishlist --------------------*/
@import 'pages/wishlist';

/*-------------------- 5.15.Cart --------------------*/
@import 'pages/cart';

/*-------------------- 5.16.Dashboard --------------------*/
@import 'pages/dashboard';

/*-------------------- 5.17.404 --------------------*/
@import 'pages/404';

/*-------------------- 5.18.Coming Soon --------------------*/
@import 'pages/coming-soon';

/*-------------------- 5.19.Category Market --------------------*/
@import 'pages/category-market';

/*-------------------- 6.Spacing + Helpers --------------------*/
@import 'config/helpers';
@import 'config/spacing';

/*-------------------- 7.Font Icons --------------------*/
// @import 'elements/font-icons';

/*-------------------- 8.HomePage --------------------*/
@import 'skins/skin';
@import 'custom';
@import 'custom/carousel';