@font-face {
	font-family: 'Tajawal';
	src: url('../../assets/fonts/Tajawal-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Tajawal';
	src: url('../../assets/fonts/Tajawal-Bold.ttf');
	font-weight: bold;
	font-style: normal;
}

// Variables
$font-size: 1.4rem !default;
$sidebar-font-size: 1.4rem !default;
$footer-font-size: 1.3rem !default;
$input-font-size: 1.4rem !default;
$button-font-size: 1.4rem !default;

$primary-color: #02066F !default;
$primary-color-dark: #020035 !default;
$primary-color-light: rgb(2 6 111 / 8%) !default;

$secondary-color: #555870 !default;
$secondary-color-dark: #262731 !default;
$link-color: $primary-color !default;

$lazy-image-background: #EBEAED;

$border-radius: 0 !default;

$body-text: #555870 !default;
$light-text: #999999 !default;
$dark-text: #262731 !default;
$headings-text: $dark-text !default;

$font-family: 'HELVETICA', sans-serif !default;
$second-font-family: 'HELVETICA', sans-serif !default;

$font-family-ar: 'HELVETICA', sans-serif !default;
$second-font-family-ar: 'HELVETICA', sans-serif !default;

$dark-gray: #262731;
$med-gray: #555870;
$light-gray: #EBEAED;

$breakpoints: (
	xs: 480px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
	xlst: 1920px
);

$max-breakpoints: (
	xs: 479px,
	sm: 575px,
	md: 767px,
	lg: 991px,
	xl: 1199px,
	xxl: 1599px
);

// directions variable was here

// ----------------------------------------
// -- preloader
// ----------------------------------------

$preloader-thickness: 2px !default;
$preloader-color-1: rgba(#000, .1) !default;
$preloader-color-2: rgba(#000, .5) !default;