/* Wishlist */
.table {
	td {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
	.price-col {
		width: 180px;
		color: $dark-text;
		font-weight: 400;
		font-size: 1.6rem;
	}

	.stock-col {
		width: 160px;
		font-weight: 400;
		font-size: 1.6rem;
	}

	.action-col {
		width: 202px;

		.btn {
			height: 40px;
			padding-top: .55rem;
			padding-bottom: .55rem;
			font-size: 1.3rem;
			text-transform: uppercase;

			i {
				font-size: 1.8rem;
				margin-right: 1rem !important;
				margin-left: 0 !important;
			}

			&.disabled {
				opacity: 1 !important;
				color: #cccccc !important;
				cursor: default;
				pointer-events: none;
				background-color: #fafafa !important;
				border-color: #ebebeb !important;
			}
		}
	}

	.remove-col {
		padding-right: 0;
		padding-left: 0;
		width: 38px;
		@include direction {
			text-align: $inline-end;
		}

		.btn-remove {
			margin-right: -.7rem;
		}
	}
}
table.no-padding{
	td {
		padding: 0;
	}
}

.product-col {
	.product {
		display: flex;
		align-items: center;
		margin: 0;
		padding-right: 2rem;
		box-shadow: none;
	}

	.product-media {
		display: inline-block;
		margin: 0;
		margin-right: 0.1rem;
		flex-shrink: 0;
		min-width: 60px;
	}

	.product-title {
		display: inline-block;
		color: $dark-text;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 1.25;
		margin: 0;

		a {
			color: inherit;
			text-align: center;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}
}

.in-stock {
	color: $primary-color;
}

.out-of-stock {
	color: #ef837b;
}

.btn-remove {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	color: #cccccc;
	background-color: transparent;
	font-size: 1.7rem;
	line-height: 1;
	border: none;
	outline: none;
	transition: all .35s;

	&:hover,
	&:focus {
		color: $dark-text;
	}
}

.action-col {
	.dropdown-menu {
		color: #666;
		border-radius: 0;
		width: 100%;
		font-size: 1.3rem;
		padding-top: .5rem;
		padding-bottom: .5rem;
	}

	.dropdown-item {
		padding: .5rem 1.5rem;
		transition: background .35s ease, color .1s ease;
	}

	.dropdown-item.active, 
	.dropdown-item:active,
	.dropdown-item:hover, 
	.dropdown-item:focus {
		color: #fff;
		background-color: $primary-color;
	}
}

@include mq('lg', 'max') {
	.product-col {
		.product-media {
			display: block;
			max-width: 60px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 2rem;
		}
		.product {
			display: block;
		}
	}
	.table-mobile,
	.table-mobile tbody,
	.table-mobile tr,
	.table-mobile td {
		width: 100% !important;
		display: block !important;
	}

	.table-mobile thead {
		display: none !important;
	}

	.table-mobile tr {
		position: relative;
		padding-top: 4.2rem;
		padding-bottom: 4rem;
	}

	.table-mobile .remove-col {
		position: absolute;
		right: 1.5rem;
		top: .5rem;
		padding: .5rem !important;
		width: auto !important;
	}

	.table-mobile tr td {
		text-align: center;
		padding: .1rem 3rem;
		border-bottom: none;
	}

	.table-mobile tr .product-col {
		padding-bottom: .6rem;
	}

	.table-mobile tr .action-col {
		padding-top: 1.5rem;

		.btn,
		.dropdown {
			max-width: 220px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.table-mobile .product-col .product {
		justify-content: center;
	}

	.table-mobile .product-col .product-title {
		@include direction {
			text-align: $inline-start;
		}
	}

	.table-mobile {
		border: .1rem solid #ebebeb;
	}

	.table-mobile tr:not(:last-child) {
		border-bottom: .1rem solid #ebebeb;
	}

	.table-mobile .cart-product-quantity {
		margin: .5rem auto;
	}

	.product-col .product {
		padding-right: 0;
	}
}

@include mq('sm', 'max') {
	.cart-bottom {
		flex-direction: column;
		margin-bottom: 4rem;

		.cart-discount .btn {
			margin-top: 0;
		}

		.btn {
			margin-left: 0 !important;
			margin-top: 1rem;
		}
	}
}