/* Checkout */
.checkout {
	@include mq('lg') {
		.col-lg-3 {
			-ms-flex: 0 0 30%;
			flex: 0 0 30%;
			max-width: 30%;
		}

		.col-lg-9 {
			-ms-flex: 0 70%;
			flex: 0 0 70%;
			max-width: 70%;
		}
	}
	

	.checkout-card{
		padding: 1rem;
		margin: .5rem 0;
		border: 1px solid $med-gray;
		border-radius: 1rem;
		.checkout-card-items{
			width : 100%;
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			@include mq('md', 'max') {
				font-size: 1.3rem;
			}
			.card-img{
				flex: 0 0 8%;
				margin: 5px 10px;
			}
			@include mq('md', 'max') {
				.card-img{
					flex: 0 0 30%;
				}
			}
			.card-info{
				flex:1;
				display: flex;
				justify-content: space-between;
				.card-desc{
					display: flex;
					flex: 1;
					align-items: center;
					h3 {
						font-size: 1.3rem;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						overflow: hidden;
						margin: 0;
					}
				}
				.price{
					display: flex;
					color: $primary-color;
					flex: 0 1 10%;
					align-items: center;
						// margin: 0px 10px;
				}
			}
			@include mq('md', 'max') {
				.card-info{
					.card-desc{
						padding: 10px 5px 0px;
						h3 {
							font-size: 1.3rem;
							-webkit-line-clamp: 3;
							margin: 0px;
						}
					}
					flex-direction: column;
					
					.card-action{
						flex-direction: row;
						align-items: center;
						.price{
							padding:0;
						}
					}
				}
			}
		}
		.checkout-card-totals{
			display: flex;
			flex-direction: column;
			flex: 0 0 50%;
			.checkout-card-row{
				display: flex;
				flex-direction: row;
			}
			.checkout-card-col{
				width: 60%;
				text-align: left;
			}
			.checkout-card-col:last-child{
				width: 40%;
				text-align: right;
			}
		}
	}

	label {
		margin-bottom: .2rem;
	}

	.form-control {
		margin-bottom: 1.3rem; 

		& + .form-control {
			margin-top: 2rem;
		}
	}

	.form-control:not(:focus) {
		background-color: #f9f9f9;
	}

	textarea.form-control {
		min-height: 150px;
	}

	.custom-control {
		margin-top: 2.5rem;

		& + .custom-control {
			margin-top: 1.8rem;
		}

		& + label {
			margin-top: 1rem;
		}
		.custom-control-label {
			font-weight: 400;
			color: #333;
		}
	}
	.btn{
		padding: 15px 0;
    	border-radius: 1rem;
	}
}

.checkout-title {
	font-weight: 500;
	font-size: 1.6rem;
	letter-spacing: 0;
	margin-top: 2.2rem;
	margin-bottom: 1.8rem;
}
.end-button {
	margin: 0 1rem;
	color: $secondary-color;
	@include direction {
		float: $inline-end;
	}
}

.checkout-discount {
	position: relative;
	max-width: 350px;
	margin-bottom: .5rem;

	label {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		padding-left: 1.1rem;
		padding-right: 1.1rem;
		font-weight: 400;
		transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		opacity: 1;
		transition: all .3s;

		span {
			color: $primary-color;
		}
	}

	.form-control {
		background-color: transparent !important;
		border-radius: .3rem;
		border: .1rem dashed #d7d7d7;
		padding-left: 1.1rem;
		padding-right: 1.1rem;

		&:focus {
			border-color: $primary-color;
			outline: none !important;
		}
	}
}

.accordion-summary {
	.card {
		overflow: hidden;
		color: $light-text;
		font-weight: 300;
		font-size: 1.2rem;
		line-height: 1.67;
		border: none;
		background-color: transparent;
	}

	.card-title {
		font-weight: 300;
		font-size: 1.4rem;
		color: $dark-text;
		letter-spacing: 0;

		.toggle-button {
			color: inherit;
			padding: .7rem 0 .7rem 3rem;

			img {
				height: 20px;
				float: left;
				margin: 0px;
				margin-right: 10px;
			}

			&:hover,
			&:focus {
				color: inherit;
			}

			small {
				font-size: 1.1rem;
				color: $light-text;
			}

			&:before {
				content: '';
				width: 1.6rem;
				height: 1.6rem;
				border-radius: 50%;
				border: .1rem solid #dadada;
				right: auto;
				left:0;
				top: .7rem;
				transform: translateY(0);
				-ms-transform: translateY(0);
			}

			&:after {
				content: '';
				display: block;
				width: .6rem;
				height: .6rem;
				position: absolute;
				left: .5rem;
				top: 1.2rem;
				border-radius: 50%;
				background-color: transparent;
				transition: all .3s;
				visibility: visible;
				opacity: 1;
			}

			&:not(.collapsed) {
				&:before {
					border-color: $primary-color;
				}

				&:after {
					background-color: $primary-color;
				}
			}
		}
		.disabled {
			color: #999999; /* or any other disabled color */
			pointer-events: none; /* disables pointer events */
		}
	}

	.card-body {
		padding: .4rem 0 .8rem 3rem;
	}
}

.paypal-link {
	cursor: pointer;
	margin-top: .2rem;

	&:hover,
	&:focus {
		color: $primary-color !important;
	}
}

@include mq('lg') {
	.summary {
		margin-top: 0;
	}
}