	/* Shopping Cart */
	.cart {
		@include mq('lg') {
			.col-lg-3 {
				-ms-flex: 0 0 30%;
				flex: 0 0 30%;
				max-width: 30%;
			}

			.col-lg-9 {
				-ms-flex: 0 70%;
				flex: 0 0 70%;
				max-width: 70%;
			}
		}

		.card {
			.btn-remove {
				position: absolute;
				z-index: 1;

				@include direction {
					#{$inline-end}: 0px;
				}
			}

			width : 100%;
			padding:5px;
			border-radius: 1rem;
			display: flex;
			flex-direction: row;
			margin-bottom: 10px;

			@include mq('md', 'max') {
				font-size: 1.4rem;
			}

			.card-img {
				flex: 0 0 12%;
			}

			@include mq('md', 'max') {
				.card-img {
					flex: 0 0 30%;
				}
			}

			.card-info {
				flex: 1;
				display: flex;

				.card-desc {
					padding: 10px;
					display: flex;
					flex: 1;
					align-items: center;

					h3 {
						font-size: 1.6rem;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}

				.card-action {
					padding: 5px 0 0;

					* {
						flex: 1;
					}

					flex: 0 1 10%;
					align-items: flex-end;
					display: flex;
					flex-direction: column;

					.price {
						color: $primary-color;
						margin: 0px 10px;
						font-weight: bold;
						padding-top: 30px;
					}

					molla-quantity-input {
						align-items: flex-end;
					}
				}
			}

			@include mq('md', 'max') {
				.card-info {
					.card-desc {
						padding: 10px 5px 0px;

						h3 {
							font-size: 1.3rem;
							-webkit-line-clamp: 3;
							margin: 0px;
						}
					}

					flex-direction: column;

					.card-action {
						flex-direction: row;
						align-items: center;

						.price {
							padding: 0;
						}
					}
				}
			}
		}
	}

	.warranty-card {
		.card-img {
			padding: 10px;
		}
	}

	.cart-product-quantity {
		width: 100px;

		.form-control {
			padding: .85rem 2.6rem;
			height: 40px;
			max-width: 100%;
			border-color: #dadada;
			background-color: #fff;
			margin: 0;
		}
	}

	.input-spinner {
		position: relative;

		.input-group-prepend,
		.input-group-append {
			position: absolute;
			top: 0;
		}

		.input-group-prepend {
			left: 0;
		}

		.input-group-append {
			right: 0;
		}

		.form-control {
			padding: .85rem 2.6rem;
			height: auto;
			border-color: #dadada;
			background-color: #fff;
			margin: 0;

			&[disabled] {
				background-color: #f9f9f9;
			}
		}
	}

	.btn.btn-spinner {
		min-width: 0;
		color: #333333;
		padding: 1.25rem .4rem;
		font-size: 1rem;
		border: none;
		background: none;

		i {
			margin: 0 !important;
		}

		&:hover,
		&:focus {
			color: $primary-color;
		}
	}

	.cart-bottom {
		display: flex;
		align-items: flex-start;
		margin-bottom: 5rem;

		.btn {
			margin-left: auto;
			min-width: 152px;
		}
	}

	.cart-discount {
		flex: 0 0 50%;
		max-width: 465px;

		.form-control {
			margin-right: 1.1rem;
		}

		.btn {
			min-width: 40px;
		}
	}


	.styles__tabby-promo-snippet--2ddd6 {
		background: white;
	}

	.installmentsAds {
		background-color: white;
		display: flex;
		border-radius: 7px;
		padding: 10px;
		width: fit-content;
		margin-bottom: 1rem;
		font-size: 1.3rem;
		line-height: 2rem;

		img {
			height: 20px;
			display: inline-block;
			margin: auto 0px;

			@include direction {
				#{$margin-inline-end}: 10px;
			}
		}

		span {
			a {
				color: $primary-color;
				text-decoration: underline;
			}
		}
	}

	.installmentsAds-tabby {
		border: 1px solid #31e8a8;
	}

	.installmentsAds-tamara {
		border: 1px solid #d47c84;
	}

	.payment-iframe {
		iframe {
			width: 769px;
			height: 769px;
		}

		@include mq('md', 'max') {
			iframe {
				width: 340px;
				height: 777px;
				padding-top: 3rem;
			}
		}
	}